import isServer from '~/shared/util/is-server';

function stringNormalizeSham() {
  if (typeof String.prototype.normalize !== 'function') {
    // eslint-disable-next-line no-extend-native, func-names
    String.prototype.normalize = function () {
      return String(this);
    };
  }
}

const Polyfills = (async () => {
  if (!isServer) {
    stringNormalizeSham();
  }
})();

export default Polyfills;
